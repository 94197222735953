<div class="headline"
	 [ngClass]="{ active: (actualState$ | async)}"
	 (click)="next()">
	<div *ngIf="actualState$ | async; else closed"
		 class="panel-trigger close"
		 title="{{'button.collapse.alt' | msgKeyCached}}">
		<mat-icon svgIcon="expand_less"></mat-icon>
	</div>

	<ng-template #closed>
		<div class="panel-trigger open"
			 title="{{'button.expand.alt' | msgKeyCached}}">
			<mat-icon svgIcon="expand_more"></mat-icon>
		</div>
	</ng-template>

	<span *ngIf="name">{{name}}</span>
	<ng-content select=".paneltitle"></ng-content>
</div>

<div class="content"
	 [@toggle]="animationState$  | async">
	<ng-content></ng-content>
</div>