<div bas-button
	 color="primary"
	 class="home"
	 (click)="returnToHome()"></div>

<div class="breadcrumb-container">
	<div class="breadcrumb-item"
		 *ngFor="let breadcrumb of breadcrumbs(); let last = last">
		<a bas-link
		   (click)="navigateRouted(breadcrumb,$event)"
		   *ngIf="!last; else span_only">{{breadcrumb.description}}</a>
		<ng-template #span_only>
			<span>{{breadcrumb.description}}</span>
		</ng-template>
	</div>
</div>