import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconNavTooltipComponent } from './icon-nav-tooltip.component';
import { IconNavTooltipContentComponent } from './content/icon-nav-tooltip-content.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
	imports: [
		CommonModule, BrowserAnimationsModule
	],
	declarations: [
		IconNavTooltipComponent,
		IconNavTooltipContentComponent
	],
	exports: [
		IconNavTooltipComponent,
		IconNavTooltipContentComponent
	]
})
export class IconNavTooltipModule { }
