import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../store/application-state';
import { BreadcrumbsQuery } from '../../store/reducers/breadcrumbs.reducer';
import { Breadcrumb } from './models/breadcrumb';

/**
 * @group Generic Compounds
 * @component Breadcrumb
 */
@Component({
	selector: 'bas-breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['./breadcrumb.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbComponent {

	breadcrumbs = this.store.selectSignal(BreadcrumbsQuery.getBreadcrumbs);

	constructor(
		private store: Store<ApplicationState>,
		private router: Router,
	) {
	}

	returnToHome() {
		this.router.navigateByUrl('workflow.do?from=menu&to=home');
	}

	isStrutsLink(url: string) {
		return url.indexOf('.do') > 0;
	}

	getExtendedUrl(breadcrumb: Breadcrumb) {
		if (this.isStrutsLink(breadcrumb.url)) {
			return '/jump.do?id=' + breadcrumb.id;
		}
		return breadcrumb.url;
	}

	navigateRouted(breadcrumb: Breadcrumb, event: MouseEvent) {
		this.router.navigateByUrl(this.getExtendedUrl(breadcrumb), /* Removed unsupported properties by Angular migration: queryParams. */ {});
		event.preventDefault();
	}
}
